.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
  scroll-margin-top: 80px;
}

.image img {
  width: 520px;
  height: 520px;
  margin-right: 80px;
}

.left {
  order: 2;
  margin-right: 0;
  margin-left: 80px;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--dark-blue-2);
}

.text h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.text h3 {
  margin-bottom: 1rem;
}

.text p {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.btn {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  font-size: 1.2rem;
  color: var(--dark-blue-2);
  font-weight: 600;
  width: max-content;
}

.btn span {
  margin-right: 12px;
}

.btn:hover {
  color: var(--orange);
}

.btn:hover svg path {
  fill: var(--orange);
}

@media screen and (max-width: 1280px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .image {
    margin-bottom: 1rem;
    margin-right: 0;
  }

  .left {
    order: 1;
    margin-left: 0;
  }

  .text {
    width: 520px;
    order: 2;
  }
}

@media screen and (max-width: 575px) {
  .image {
    width: 295px;
    height: 295px;
  }

  .image img {
    width: 100%;
    height: 100%;
    margin-right: 0;
  }

  .text {
    width: 295px;
  }

  .text h1 {
    font-size: 2rem;
  }
}
