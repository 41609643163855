.container {
  padding: 110px 1rem 10rem;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.post {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.blockContent h1 {
  font-family: "Paralucent", sans-serif;
  font-size: 2.4rem;
  color: var(--dark-blue-2);
  margin-bottom: 1.5rem;

  @media screen and (min-width: 768px) {
    font-size: 3.5rem;
    margin-bottom: 2rem;
  }
}

.blockContent h2 {
  font-family: "Paralucent", sans-serif;
  font-size: 1.8rem;
  color: var(--dark-blue-2);
  margin-top: 2.5rem;
  margin-bottom: 1rem;

  @media screen and (min-width: 768px) {
    font-size: 2.6rem;
  }
}

.blockContent h3 {
  font-family: "Paralucent", sans-serif;
  font-size: 1.4rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;

  @media screen and (min-width: 768px) {
    font-size: 2.1rem;
  }
}

.blockContent h4 {
  font-family: "Paralucent", sans-serif;
  font-size: 1.1rem;
  margin-bottom: 0.2rem;
  color: #666;

  @media screen and (min-width: 768px) {
    font-size: 1.4rem;
  }
}

.blockContent p,
.blockContent a,
.blockContent li,
.blockContent blockquote {
  font-size: 1.2rem;
  line-height: 1.5;

  @media screen and (min-width: 768px) {
    font-size: 1.4rem;
    line-height: 1.5;
  }
}

.blockContent p {
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: black;
}

.blockContent blockquote {
  font-style: italic;
  padding: 0.5rem 0 0.5rem 1rem;
  margin-bottom: 1.5rem;
  border-left: 2px solid #aaa;
}

.blockContent ul {
  padding-left: 1.8rem;
  margin-bottom: 1.5rem;
}

.blockContent li {
  padding-left: 0.5rem;
  list-style: disc;
}

.blockContent figure {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0 2rem 0;
}

.blockContent figure img {
  object-fit: contain;
  width: 100%;
  max-height: 500px;
}

.blockContent figure figcaption {
  font-size: 1.2em;
  color: #888;
  margin-top: 1rem;
}

.mainImage {
  margin-bottom: 2rem;
}

.mainImage img {
  width: 100%;
  border-radius: 0.5rem;
}

.authorDateContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.authorDateContainer img {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  margin-right: 1rem;
}

.authorDateDivider {
  margin: 0 1rem;
}

.authorDateDivider::before {
  content: "|";
}

.categoriesContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}

.category {
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 99rem;
  background-color: var(--pink);
  color: var(--dark-blue-2);
}
.category:hover {
  background-color: var(--dark-blue-2);
  color: var(--pink);
}

.goBackLink a {
  margin-top: 3rem;
  display: inline-flex;
  padding: 1rem 1.5rem;
  align-items: center;
  font-weight: 600;
  border-radius: 99rem;
  background-color: var(--dark-blue-2);
  color: white;
}

.goBackLink a span {
  margin-right: 1rem;
}

.goBackLink a svg path {
  fill: white;
}

.goBackLink a:hover {
  background-color: var(--orange);
}
