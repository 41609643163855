.btn {
  padding: 0 2rem;
  border: none;
  outline: none;
  height: 40px;
  line-height: 40px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-color: var(--dark-blue-2);
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.btn a {
  color: white;
  font-size: 1.2rem;
  position: relative;
  z-index: 1;
}

.btn::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0 0;
  transform: rotate(90deg);
  background-color: var(--orange);
}

.btn:hover::before {
  animation: btnAnim 0.7s ease-in-out 1 forwards;
}

@keyframes btnAnim {
  20% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(0deg) translateY(-10px);
  }
  60% {
    transform: rotate(0deg) translateY(0px);
  }
  80% {
    transform: rotate(0deg) translateY(10px);
  }
  100% {
    transform: rotate(0deg) translateY(0px);
  }
}
