.container {
  width: 100%;
  background-color: var(--pink);
  display: flex;
  padding: 80px 0 0 102px;
}

.text {
  width: 40%;
  z-index: 1;
}

.image {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  max-height: 500px;
}

.text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: var(--dark-blue-2);
}

.title {
  font-size: 2.7rem;
  line-height: 90px;
  margin-bottom: 1rem;
}

.description {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.image img {
  height: 100%;
  object-fit: cover;
}

.link {
  display: flex;
  border: none;
  outline: none;
  height: 30px;
  background-color: transparent;
  align-items: center;
  cursor: pointer;
  color: var(--dark-blue-2);
  font-size: 1.2rem;
}

.link span:hover {
  color: var(--orange);
}

.link span:hover svg path {
  fill: var(--orange);
}

@media screen and (max-width: 1100px) {
  .container {
    margin-top: 0;
    height: auto;
    flex-direction: column;
    padding: 0;
    padding-top: 80px;
    overflow: hidden;
  }

  .description {
    margin-bottom: 1rem;
  }

  .text,
  .image {
    width: 100%;
  }

  .image img {
    transform: translateX(260px);
  }

  .text {
    padding: 1rem 15px;
  }

  .title {
    font-size: 1.5rem;
    line-height: initial !important;
  }
}

@media screen and (max-width: 457px) {
  .image img {
    width: 100%;
    transform: translateX(160px);
  }
}
