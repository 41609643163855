.row-title-text {
  color: var(--dark-blue-2);
}

.row-title-text:hover {
  color: var(--orange);
}

.row-content-text a {
  color: var(--dark-blue-2);
}

.row-content-text a:hover {
  color: var(--orange);
}

.arrow-image svg {
  fill: var(--dark-blue-2) !important;
}

.faq-title {
  padding-top: 0 !important;
}

.faq-title h2 {
  color: var(--dark-blue-2) !important;
  font-weight: 900 !important;
  font-family: "Paralucent", sans-serif;
}

.faq-row-wrapper {
  transform: translateY(-50px);
  padding: 25px;
  margin-right: 25px;
  border-radius: 0.5rem;
  width: 620px;
  background-color: var(--creme-2) !important;
}

@media screen and (max-width: 768px) {
  .faq-row-wrapper {
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%;
  }
  .faq-title h2 {
    font-size: 2.2rem !important;
  }
  .row-title-text {
    font-size: 1.3rem !important;
  }
}
