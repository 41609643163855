.playerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.playerBackdrop {
  margin: 3%;
  padding: 3%;
  max-width: 700px;
  background-color: var(--light-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  transform: translateY(-100px);
}

@media screen and (max-width: 1100px) {
  .playerBackdrop {
    max-width: 100%;
  }
}
