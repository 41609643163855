.container {
  display: none;
}

@media screen and (max-width: 1100px) {
  .container {
    width: 100%;
    height: 80px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .btn {
    cursor: pointer;
  }
}

.logo {
  display: block;
  max-width: 180px;
  margin-right: 1rem;
}
.logo img {
  width: 100%;
}
