.heroAreaContainer {
  padding: 80px 102px 1rem;
  display: flex;
  background-color: var(--pink);
  background-image: url("../../assets/images/new-feature-background.png");
  justify-content: space-evenly;
  background-repeat: repeat-x;
  align-items: center;
  min-height: 250px;
}

.left,
.right {
  width: 40%;
  color: var(--dark-blue-2);
}

.left h1 {
  font-size: 5rem;
}

.right {
  padding: 1rem;
  background-color: var(--pink);
}

.right p {
  font-size: 1.2rem;
}

@media screen and (max-width: 1100px) {
  .heroAreaContainer {
    flex-direction: column;
    padding: 80px 15px 15px 15px;
  }

  .left,
  .right {
    width: 100%;
  }

  .left h1 {
    font-size: 2.5rem;
  }

  .left {
    margin-bottom: 1rem;
  }
}

.filterContainer {
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
  padding: 2rem 2rem 0;
}

.posts {
  max-width: 2000px;
  margin: 0 auto;
  padding: 2rem 1rem 10rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
  .posts {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.postsInfo {
  font-size: 1.5rem;
  color: #888;
}

.filterLabel {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: var(--dark-blue-2);
}

.filterSelect {
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  border: 1px solid var(--dark-blue-2);
  background: var(--dark-blue-2);
  color: white;
}

/* Post */

.post {
  width: 100%;
  margin-bottom: 3rem;
  padding: 0.5rem 0;
  color: black;
  font-size: 1.2rem;
}
.post:visited {
  color: black;
}

@media screen and (min-width: 768px) {
  .post {
    width: 50%;
    padding: 0.5rem;
  }
}

@media screen and (min-width: 1280px) {
  .post {
    width: 25%;
  }
}

.postContent {
  padding: 1rem;
  border-radius: 8px;
}

.post:hover .postContent {
  background-color: var(--creme-2);
  color: var(--dark-blue-2);
}

.postImage {
  margin-bottom: 1rem;
}

.postImage img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 0.5rem;
}

.postTitle {
  font-size: 1.5em;
}

.postTitle,
.postDescription {
  margin-bottom: 1rem;
}

.postBtn {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--dark-blue-2);
}

.postBtn span {
  margin-right: 0.5rem;
}
