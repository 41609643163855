.container {
  width: 100%;
  height: 250px;
  background-color: var(--dark-blue-2);
  border-radius: 0.5rem;
  padding: 2rem 75px;
  display: flex;
  position: relative;
  align-items: flex-start;
}

.testimonial {
  width: 330px;
  margin-right: 75px;
  flex-shrink: 0;
}

.testimonial h1 {
  color: var(--light-blue);
  font-size: 50px;
}

.slides {
  display: flex;
  flex-direction: column;
}

.slide {
  position: absolute;
  opacity: 0;
}

.activeSlide {
  opacity: 1;
  transition: opacity 0.3s;
}

.slideText {
  font-size: 2rem;
  font-weight: 800;
  color: white;
  margin-bottom: 1rem;
}

.client {
  display: flex;
  align-items: center;
  max-height: 80px;
}

.companyLogo {
  margin-right: 1rem;
}

.clientInfo {
  color: var(--light-blue);
  font-size: 1.2rem;
}

.dots {
  display: flex;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.dots span {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 1rem;
  background-color: var(--light-blue);
  cursor: pointer;
  transition: all 0.3s linear;
}

.dots span:last-child {
  margin-right: 0;
}

.dots span.active {
  background-color: var(--orange);
}

.activeSlide p {
  color: var(--light-blue);
}

@media screen and (max-width: 1280px) {
  .container {
    height: auto;
    padding: 2rem;
    flex-direction: column;
  }

  .testimonial {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .testimonial h1 {
    font-size: 30px;
  }

  .slideText {
    margin-bottom: 1rem;
    font-size: 1.3rem;
  }

  .client {
    max-height: initial;
  }

  .clientInfo {
    font-size: 1rem;
  }
}
