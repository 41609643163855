.container {
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--pink);
  display: flex;
  padding: 80px 0 0 102px;
  scroll-padding-top: 80px;
}

.text {
  width: 40%;
  z-index: 1;
}

.image {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transform: translateY(-100px);
  animation: fadeInImg 0.5s linear 0.3s 1 forwards;
}

.text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: var(--dark-blue-2);
}

.title {
  font-size: 2.9rem;
  line-height: 45px;
  margin-bottom: 1rem;
  opacity: 0;
  transform: translateY(-100px);
  animation: fadeInTitle 0.5s linear 1 forwards;
}

.title h1 {
  font-family: "Paralucent", sans-serif;
}

.description {
  font-size: 1.2rem;
  line-height: 11px;
  margin-bottom: 2rem;
  opacity: 0;
  transform: translateY(-100px);
  animation: fadeInDes 0.5s linear 0.2s 1 forwards;
}

.description p {
  margin-top: 2rem;
}

.image img {
  height: 100%;
}

.arrow {
  position: absolute;
  top: calc(100vh - 55px);
  left: 50%;
  transform: translateX(-50%);
}

@keyframes fadeInTitle {
  50% {
    opacity: 0.5;
    line-height: 45px;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    line-height: 90px;
    transform: translateY(0px);
  }
}

@keyframes fadeInDes {
  50% {
    opacity: 0.5;
    line-height: 11px;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    line-height: 22px;
    transform: translateY(0px);
  }
}

@keyframes fadeInImg {
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media screen and (max-width: 1100px) {
  .container {
    position: relative;
    height: auto;
    flex-direction: column;
    padding: 0;
    padding-top: 80px;
  }

  .arrow {
    display: none;
  }

  .text,
  .image {
    width: 100%;
  }

  .text {
    padding: 1rem 15px;
  }

  .title {
    font-size: 1.5rem;
    line-height: initial !important;
  }
}

@media screen and (max-width: 750px) {
  .image img {
    width: 100%;
  }
}
