.container {
  padding: 102px 102px 0 102px;
  background-color: var(--light-blue);
}

.formContainer {
  width: 100%;
}

.header {
  margin-bottom: 3rem;
}

.header h1 {
  color: var(--dark-blue-2);
  font-size: 5rem;
}

.inner {
  display: flex;
}

.text,
.form {
  width: 50%;
  z-index: 1;
}

.text {
  color: var(--dark-blue-2);
}

.title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--dark-blue-2);
}

.numberedList {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
}

.number {
  width: 40px;
  text-align: center;
  margin-right: 1rem;
}

.number h1 {
  font-size: 3rem;
}

.nt {
  width: 540px;
  font-size: 1.2rem;
  font-weight: 500;
}

.footerBtn {
  font-weight: 600;
  height: 40px;
}

.footerBtn span {
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
}

.footerBtn span svg {
  margin-left: 0.5rem;
}

.signupFrom {
  padding: 2rem 0 1rem 0;
}

.signupFrom input {
  width: 100%;
  height: 40px;
  font-size: 1.2rem;
  padding: 12px;
  border: none;
  outline: none;
  margin-bottom: 2rem;
}

.signupFrom input:last-child {
  margin-bottom: 0;
}

.errorMsg {
  color: red;
  margin-bottom: 1rem;
}

.plans {
  width: 100%;
  height: 40px;
  box-shadow: inset 0 0 0 2px var(--dark-blue-2);
  border-radius: 40px;
  display: flex;
  position: relative;
  margin-bottom: 2rem;
}

.plansDisabled {
  filter: brightness(250%) grayscale(80%);
}

.plansDisabled > * {
  cursor: default !important;
}

.plan {
  width: calc(100% / 3);
  height: 100%;
  color: var(--dark-blue-2);
  font-size: 1.2rem;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  z-index: 1;
}

.plan:first-child {
  color: var(--white);
}

.planDisabled {
  cursor: default;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--dark-blue-2);
  width: calc(100% / 3);
  height: 100%;
  border-radius: 40px;
  transition: all 0.3s linear;
}

.btn {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.btn button {
  padding: 0 1.5rem;
  height: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: var(--orange);
  color: var(--white);
  margin-right: 2rem;
  border-radius: 40px;
  font-size: 1.1rem;
}

.btn button:disabled {
  filter: saturate(50%);
  cursor: default;
}

.btn a {
  height: 100%;
  display: flex;
  align-items: center;
  color: var(--dark-blue-2);
  font-weight: 600;
}

.btn a svg {
  margin-left: 1rem;
}

.formFooter {
  color: var(--dark-blue-2);
}

.formFooter a {
  color: var(--dark-blue-2);
  text-decoration: underline;
  font-weight: 600;
}

.image {
  width: 100%;
  transform: translateY(-100px);
}

@media screen and (max-width: 1100px) {
  .container {
    padding: 80px 0 0 0;
  }

  .header {
    margin-bottom: 1rem;
    padding: 0 15px;
  }

  .header h1 {
    font-size: 2.5rem;
  }

  .inner {
    flex-direction: column;
    margin-bottom: 2rem;
    padding: 0 15px;
  }

  .text,
  .form {
    width: 100%;
  }

  .text {
    margin-bottom: 2rem;
  }

  .plan {
    font-size: 1rem;
  }

  .numberedList {
    align-items: center;
  }

  .nt {
    width: 100%;
  }

  .signupFrom {
    padding-top: 0;
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transform: translateY(0px);
  }
}

@media screen and (max-width: 960px) {
  .image img {
    width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .formContainer {
    overflow: hidden;
  }

  .inner {
    margin-bottom: 6rem;
  }

  .image {
    padding-bottom: 6rem;
  }

  .image img {
    transform: scale(1.5);
  }
}
