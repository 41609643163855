.heroArea {
  width: 100%;
  height: 660px;
  padding-top: 80px;
  padding-left: 102px;
  display: flex;
  background-color: var(--light-blue);
}

.text {
  width: 45%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--dark-blue-2);
}

.title h1 {
  font-size: 6rem;
  line-height: 90px;
}

.title {
  margin-bottom: 2rem;
}

.subTitle {
  margin-bottom: 1rem;
}

.body p {
  font-size: 1.2rem;
}

.imgContainer {
  width: 55%;
  position: relative;
}

.imgContainer img {
  position: absolute;
  right: 0;
  height: 100%;
}

.calendarContainer {
  padding: 0 10rem 2rem 10rem;
  transform: translateY(-50px);
}

@media screen and (max-width: 1100px) {
  .heroArea {
    flex-direction: column;
    padding: 80px 0 2rem 0;
    height: auto;
  }

  .text {
    width: 100%;
    margin-bottom: 2rem;
    padding: 0 15px;
  }

  .title h1 {
    font-size: 3rem;
    line-height: initial;
  }

  .imgContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 400px;
  }

  .imgContainer img {
    position: initial;
  }

  .calendarContainer {
    padding: 0 15px 1rem 15px;
  }
}

@media screen and (max-width: 860px) {
  .calendarContainer {
    transform: translateY(-50px);
    overflow: hidden;
  }
}
