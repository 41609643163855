.container {
  width: 50%;
  color: var(--dark-blue-2);
  position: relative;
}

p {
  margin-top: 0.5rem;
}

.signupCheck {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25%;
  transform: translate(-50%, -50%);
}

.signupConfetti {
  position: absolute;
  top: -50%;
  width: 100%;
}
