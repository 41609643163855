.container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: var(--light-blue);
  padding: 0 102px;
  position: relative;
  z-index: 9;
}

.text {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 102px;
  transform: translateY(-50%);
  color: var(--dark-blue-2);
}

.image img {
  height: 100%;
}

.title,
.description {
  margin-bottom: 2rem;
}

.title h1 {
  font-size: 5.6rem;
  line-height: 80px;
}

.description {
  width: 65%;
  font-size: 1.2rem;
}

.description p {
  margin-top: 2rem;
}

@media screen and (max-width: 1100px) {
  .container {
    flex-direction: column;
    justify-content: initial;
    height: auto;
    padding: 0 15px;
    padding-top: 2rem;
    overflow: hidden;
  }

  .text {
    width: 100%;
    position: initial;
    transform: translateX(0px);
  }

  .title h1 {
    font-size: 3rem;
    line-height: initial;
  }

  .description {
    width: 100%;
  }

  .image {
    width: 100%;
  }

  .image img {
    width: 100%;
    transform: translateY(10px);
  }
}

@media screen and (max-width: 375px) {
  .container {
    padding-bottom: 5rem;
  }

  .text {
    margin-bottom: 7rem;
  }

  .image img {
    transform: scale(1.7) translateY(10px);
  }
}
