.container {
  width: 100%;
  background-color: var(--creme-2);
  border-radius: 0.5rem;
  padding: 2.5rem 2rem;
  color: var(--dark-blue-2);
  text-align: left;
  position: relative;
  transition: all 0.2s linear;
}

.container h2 {
  padding-bottom: 10px;
}

.container:nth-child(3) {
  background-color: var(--light-blue);
}

.container:nth-child(4) {
  background-color: var(--dark-blue-2);
  color: var(--white);
}

.title {
  padding-bottom: 1.5rem;
  border-bottom: 2px solid var(--dark-blue-2);
  margin-bottom: 1.5rem;
  font-size: 2rem;
  text-align: center;
}

.container:nth-child(4) .title {
  border-bottom: 2px solid var(--pink);
  color: var(--pink);
}

.subtitle {
  padding: 0 1rem;
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

.price {
  margin-bottom: 2rem;
}

.price h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.btnContainer {
  margin-bottom: 2rem;
  text-align: center;
}

.btnContainer a {
  padding: 0.5rem 1.5rem;
  height: 40px;
  line-height: 40px;
  color: var(--white);
  background-color: var(--orange);
  cursor: pointer;
  border-radius: 40px;
  border: none;
  outline: none;
  font-size: 1.2rem;
}

.btnContainer a:hover {
  background-color: var(--dark-blue-2);
}

.footer {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.footer span {
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.contact {
  font-size: 1.2rem;
}

.container ul {
  width: 100%;
  margin-bottom: 2rem;
}

.container ul li {
  width: 100%;
  border-bottom: 1px solid var(--pink);
  display: flex;
  align-items: center;
  text-align: left;
  padding: 10px 0;
}

.container ul li:last-child {
  border-bottom: none;
}

.container ul li svg {
  margin-right: 1rem;
}

@media screen and (max-width: 1100px) {
  .title {
    font-size: 1.5rem;
  }
}
