.container {
  display: none;
}

@media screen and (max-width: 1100px) {
  .container {
    display: block;
    padding: 1rem;
    background-color: var(--dark-blue-2);
    width: 100%;
    min-height: 100vh;
    transition: all 0.2s linear;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
  }

  .header {
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .body {
    padding-bottom: 1rem;
    margin-bottom: 2.5rem;
    width: 100%;
    border-bottom: 1px solid var(--pink);
  }

  .body a {
    color: var(--white);
    font-size: 1.2rem;
    display: block;
    margin-bottom: 1.5rem;
  }

  .body span {
    color: var(--white);
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .body span svg {
    display: none;
  }

  .body span p,
  .body span svg {
    cursor: pointer;
  }

  .footer a {
    display: inline-block;
    padding: 0.5rem 1.5rem;
    color: var(--white);
    border: 1px solid var(--white);
    border-radius: 40px;
    font-size: 1.2rem;
  }
}
