.playButtonContainer {
  aspect-ratio: 16 / 9;
  width: 640px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1100px) {
  .playButtonContainer {
    width: 87vw;
  }
}

.playButton {
  width: 143px;
  height: 143px;
  border-radius: 50%;
  border: 2px solid var(--dark-blue-2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark-blue-2);
  font-size: 1rem;
  cursor: pointer;
  user-select: none;
}

.playButton svg {
  margin-right: 5px;
}

.playButton:hover {
  border-color: var(--orange);
  color: var(--orange);
}

.playButton:hover svg path {
  fill: var(--orange);
}
