:root {
  --white: #ffffff;
  --creme-2: #f4ede9;
  --orange: #f37328;
  --green-2: #41b196;
  --light-blue: #b0d8f4;
  --super-dark-blue: #16294d;
  --dark-blue-2: #1d299a;
  --pink: #ffd2d5;
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Paralucent";
  src: url("./assets/fonts/ec4abd87-b5a8-437a-9d51-43dca080b28d.woff") format("woff"),
    url("./assets/fonts/4f53693e-7b7c-4c23-9c83-2f2c34c00397.woff2") format("woff2");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: "Karla", sans-serif;
}

p {
  color: var(--dark-blue-2);
}

a {
  text-decoration: none;
}

a:visited {
  color: var(--dark-blue-2);
}

h1 {
  font-family: "Paralucent", sans-serif;
}
