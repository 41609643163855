.scroller {
  width: 100%;
  z-index: 9;
  position: relative;
  padding: 0 102px 5rem 102px;
  background-color: white;
}

.usp {
  padding: 0 58px;
}

@media screen and (max-width: 850px) {
  .scroller {
    padding: 0 15px 2rem 15px;
  }

  .usp {
    padding: 0;
  }
}
