.heroArea {
  width: 100%;
  height: 660px;
  padding-top: 80px;
  padding-left: 102px;
  display: flex;
  background-color: var(--light-blue);
}

.text {
  width: 40%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--dark-blue-2);
}

.title h1 {
  font-size: 6rem;
  line-height: 90px;
}

.title {
  margin-bottom: 2rem;
}

.body p {
  font-size: 1.2rem;
}

.imgContainer {
  width: 55%;
  position: relative;
}

.imgContainer img {
  position: absolute;
  right: 0;
  height: 100%;
}

.priceContainer {
  padding: 0 10rem 10rem 10rem;
  display: flex;
  justify-content: center;
}

.priceInner {
  width: calc(4rem + (2 * 520px));
  transform: translateY(-50px);
}

@media screen and (max-width: 1100px) {
  .heroArea {
    height: auto;
    margin-top: 80px;
    padding: 3rem 15px;
    flex-direction: column;
  }

  .text {
    width: 100%;
  }

  .title {
    margin-bottom: 1rem;
  }

  .title h1 {
    font-size: 3rem;
    line-height: initial;
  }

  .imgContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .imgContainer img {
    position: initial;
  }

  .priceContainer {
    padding: 0 15px 5rem 15px;
    display: flex;
    justify-content: center;
  }

  .priceInner {
    width: 100%;
    grid-template-columns: repeat(auto-fit, 100%);
  }
}

@media screen and (max-width: 860px) {
  .imgContainer img {
    width: 100%;
    object-fit: cover;
    transform: scale(1.5);
  }

  .text {
    margin-bottom: 4rem;
  }

  .heroArea {
    overflow: hidden;
  }
}
