.container {
  width: 100%;
  height: 80px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.leftNav,
.rightNav {
  display: flex;
  align-items: center;
}

.logo {
  width: 235px;
  display: inline-block;
  margin-right: 3rem;
}
.logo img {
  width: 100%;
}

.nav a {
  color: var(--dark-blue-2);
  font-size: 1.2rem;
}

.nav a:hover {
  color: var(--orange);
}

.nav a.active {
  color: var(--orange);
}

.nav a {
  margin-right: 1rem;
}

.userAccess {
  margin-right: 1rem;
}

.userAccess a {
  font-size: 1.2rem;
  color: var(--dark-blue-2);
  padding-right: 1rem;
  padding-left: 1rem;
}

.userAccess a.active {
  color: var(--orange);
}

.userAccess a:hover {
  color: var(--orange);
}

.userAccess a:first-child {
  border-right: 2px solid var(--dark-blue-2);
}

.bookDemo a {
  display: inline-block;
  padding: 0 1.5rem;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  font-size: 1.2rem;
  box-shadow: inset 0 0 0 2px var(--dark-blue-2);
  color: var(--dark-blue-2);
  position: relative;
  overflow: hidden;
}

.bookDemo a::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform-origin: 0 0;
  transform: rotate(90deg);
  background-color: var(--dark-blue-2);
}

.bookDemo a:hover {
  color: white;
}

.bookDemo a:hover::before {
  animation: btnAnim 0.5s ease-in-out 1 forwards;
}

@keyframes btnAnim {
  20% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(0deg) translateY(-10px);
  }
  60% {
    transform: rotate(0deg) translateY(0px);
  }
  80% {
    transform: rotate(0deg) translateY(10px);
  }
  100% {
    transform: rotate(0deg) translateY(0px);
  }
}

@media screen and (max-width: 1100px) {
  .container {
    display: none;
  }
}
