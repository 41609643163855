.container {
  position: relative;
  z-index: 9;
  width: 100%;
  background-color: var(--super-dark-blue);
  padding: 102px;
}

.inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.flexItem:first-child {
  max-width: 300px;
}

.logoContainer {
  margin-bottom: 2rem;
}

.logo {
  margin-right: 10px;
  width: 100%;
}

.header {
  height: 47px;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

.header p {
  color: var(--light-blue);
}

.body {
  color: var(--white);
  font-size: 1.1rem;
}

.body p {
  color: var(--light-blue);
}

.body ul li {
  margin-bottom: 1.2rem;
}

.body ul li:last-child {
  margin-bottom: 0rem;
}

.body ul li p {
  color: white;
  font-size: 1.1rem;
}

.body ul li a {
  font-size: 1.1rem;
  color: white;
}

.body ul li span {
  margin-right: 1rem;
}

.contact a {
  display: flex;
}

@media screen and (max-width: 1285px) {
  .container {
    padding: 40px;
  }

  .inner {
    flex-wrap: wrap;
  }

  .flexItem {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 660px) {
  .container {
    padding: 2.5rem 15px;
    position: relative;
  }

  .flexItem {
    margin-bottom: 4rem;
  }

  .flexItem:first-child {
    max-width: 100%;
  }

  .flexItem .header {
    margin-bottom: 1rem;
  }
}
